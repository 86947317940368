// extracted by mini-css-extract-plugin
export var mainContainer = "team-module--mainContainer--8phRb";
export var wrapper = "team-module--wrapper--fPMHl";
export var verticalContainer = "team-module--verticalContainer--OSBGd";
export var verticalContainerInner = "team-module--verticalContainerInner--Pc9Fm";
export var blogBackground = "team-module--blogBackground--ZMXSA";
export var breadCrumbsSection = "team-module--breadCrumbsSection--j4kQB";
export var memberContainer = "team-module--memberContainer--90W0c";
export var memberContainerInner = "team-module--memberContainerInner--uIK+m";
export var memberInfo = "team-module--memberInfo--0w3y3";
export var memberName = "team-module--memberName--G2DQc";
export var cityCountry = "team-module--cityCountry--Ks92Y";
export var memberJob = "team-module--memberJob--Td+6u";
export var teamContainer = "team-module--teamContainer--ZqG8F";
export var headingContainer = "team-module--headingContainer--4xB-v";
export var shareContainer = "team-module--shareContainer--T11Gz";
export var linkContainer = "team-module--linkContainer--rqLwJ";
export var profileLink = "team-module--profileLink--kAYa4";
export var accordion = "team-module--accordion--I2gbQ";
export var modalContainer = "team-module--modalContainer--Wfa+7";
export var modalContainerInner = "team-module--modalContainerInner--j8jG6";