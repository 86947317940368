import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SEO from 'components/seo'
import fallbackImage from 'images/fallbackimage.svg'
import { graphql, Link } from 'gatsby'
import { composeClasses, formatDateWithDayJs, jsonId } from 'utils'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as BlogStyles from 'styles/blog.module.css'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import * as TeamStyles from '../styles/team.module.css'
import parse from 'html-react-parser'
import HelmetExport from 'react-helmet'

export const query = graphql`
  query($first: Int) {
    wordPress {
      categories(first: $first) {
        edges {
          node {
            name
            slug
            count
            posts(first: $first) {
              edges {
                node {
                  tags {
                    edges {
                      node {
                        name
                        slug
                      }
                    }
                  }
                  author {
                    node {
                      firstName
                      lastName
                      name
                    }
                  }
                  date
                  featuredImage {
                    node {
                      sourceUrl
                    }
                  }
                  title
                  slug
                  postFieldGroup {
                    minuteRead
                  }
                  excerpt
                }
              }
            }
          }
        }
      }
      pages(where: { name: "Post" }) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
            blogFieldGroup {
              ceoPick {
                ceoPostPicks {
                  ... on wordPress_Post {
                    id
                    date
                    content
                    slug
                    title
                    postFieldGroup {
                      minuteRead
                    }
                    excerpt
                    featuredImage {
                      node {
                        sourceUrl
                        description
                      }
                    }
                    author {
                      node {
                        firstName
                        lastName
                        name
                      }
                    }
                  }
                }
              }
            }
            title
          }
        }
      }
      posts(first: $first) {
        edges {
          node {
            tags {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            excerpt
            title
            featuredImage {
              node {
                sourceUrl
              }
            }
            date
            slug
            author {
              node {
                firstName
                lastName
                name
                avatar {
                  url
                }
              }
            }
            slug
            postFieldGroup {
              minuteRead
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const generateFeaturedPostData = data => {
  const featuredEdges = data.wordPress.posts.edges.find(
    e => !!e.node.tags.edges.find(e => e.node.name.toLowerCase() === 'featured')
  )
  if (!featuredEdges) {
    // return the first as the featured
    return data.wordPress.posts.edges[0].node
  }
  return featuredEdges.node
}

const BlogPage = ({ data, pageContext }) => {
  const [featuredPost] = useState(generateFeaturedPostData(data))
  const categoryData = data.wordPress.categories.edges
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  // eslint-disable-next-line
  const CategoryList = ({ categoryData = [] }) => {
    return (
      <div>
        <div className={BlogStyles.categoryContainer}>
          <div className={BlogStyles.centerDiv}>
            {categoryData.slice(0, 5).map((item, index) => {
              return (
                <Link
                  className={BlogStyles.categoryLinkStyle}
                  key={index}
                  to={`/post/${item.node.slug}`}
                >
                  {item.node.name}
                </Link>
              )
            })}
          </div>
        </div>
        <div>
          {categoryData.slice(0, 5).map((item, index) => {
            const posts = item.node.posts
            return (
              <div
                className={BlogStyles.categoryMain}
                key={index.toString()}
              >
                <div
                  className={composeClasses(
                    BlogStyles.categorySectionsItems,
                    BlogStyles.allPostLink
                  )}
                  key={index}
                >
                  <h3>{item.node.name}</h3>
                  {item.node.count > 3 ? (
                    <Link to={`/post/${item.node.slug}`}>
                      {`see all ${item.node.count} posts >>`}
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
                <hr className={BlogStyles.horizontalLine} />
                {
                  <div className={BlogStyles.cloudNativeSection}>
                    {posts.edges.slice(0, 3).map((item, index) => {
                      return (
                        <Link
                          key={index.toString()}
                          to={`/post/${item.node.slug}`}>
                          <div
                            className={
                              BlogStyles.cloudNativeModernisationContainer
                            }
                          >
                            <h4> {item.node.title}</h4>
                            <span>{formatDateWithDayJs(item.node.date)}</span>
                            <img
                              alt='featureImage'
                              height={160}
                              src={item.node.featuredImage.node.sourceUrl}
                              width={336}
                            />
                            <span>{parse(item.node.excerpt.substring(0, 180))}</span>
                            <span>
                              {item.node.author.node.name} |{' '}
                              {item.node.postFieldGroup.minuteRead} Minute Read
                            </span>
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const getPostFeaturedImageUrl = post => {
    return post.featuredImage === null
      ? fallbackImage
      : post.featuredImage.node.sourceUrl
  }

  const getFeaturedPost = post => {
    const image = getPostFeaturedImageUrl(post)
    const featuredEdges = data.wordPress.posts.edges.find(
      e =>
        !!e.node.tags.edges.find(e => e.node.name.toLowerCase() === 'featured')
    )
    const allFeaturedPosts = data.wordPress.posts.edges.filter(
      e =>
        !!e.node.tags.edges.find(e => e.node.name.toLowerCase() === 'featured')
    )
    const filteredPosts = allFeaturedPosts.filter(post => {
      return featuredEdges.node.slug !== post.node.slug
    })

    return (
      <div key={post.slug}>
        <div className={BlogStyles.postContainerTest}>
          <div className={BlogStyles.divider}>
            <Link to={`/post/${post.slug}`}>
              <div
                className={composeClasses(
                  BlogStyles.posts,
                  BlogStyles.featuredPosts
                )}
              >
                <div
                  className={composeClasses(
                    BlogStyles.thumbnail,
                    BlogStyles.featuredThumbnail
                  )}
                  style={{ backgroundImage: `url('${image}')` }}
                />
                <div className={BlogStyles.postContent}>
                  <div
                    className={BlogStyles.postHeading}
                    to={`/post/${post.slug}`}
                  >
                    <h3>{post.title}</h3>
                  </div>
                  <p className={BlogStyles.postDateStamp}>
                    {formatDateWithDayJs(post.date)}
                  </p>
                  <div className={BlogStyles.postDescription}>
                    <span className={BlogStyles.postExcerpt}>
                      {parse(post.excerpt.substring(0, 140))}
                      ...&nbsp;
                      <span className={BlogStyles.postReadMore}>Read more</span>
                    </span>
                  </div>
                  <div
                    className={BlogStyles.postAuthorName}
                    to={`/member/${post.author.node.firstName.toLowerCase()}-${post.author.node.lastName.toLowerCase()}`}
                  >
                    {post.author.node.name} | {post.postFieldGroup.minuteRead}{' '} Minute Read
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {filteredPosts.length > 0 && (
          <div className={BlogStyles.featuredFlex}>
            {filteredPosts.slice(0, 2).map((filteredPost, index) => (
              <div
                className={BlogStyles.featuredFlexContainer}
                key={index.toString()}
              >
                <Link to={`/post/${filteredPost.node.slug}`}>
                  <div className={BlogStyles.ceoFlexWrapper}>
                    <div className={BlogStyles.ceoInnerFlex}>
                      <div
                        className={BlogStyles.ceoPickedImg}
                        style={{
                          backgroundImage: `url('${filteredPost.node.featuredImage.node.sourceUrl}')`
                        }}
                      />
                      <div>
                        <h4 className={BlogStyles.featuredTitleStyle}>
                          {filteredPost.node.title}
                        </h4>
                        <div className={BlogStyles.pickedDateStyle}>
                          {formatDateWithDayJs(filteredPost.node.date)}
                        </div>
                        <div>
                          <div className={BlogStyles.pickedDescriptionStyle}>
                            <span className={BlogStyles.postExcerpt}>
                              {parse(
                                filteredPost.node.excerpt.substring(0, 70)
                              )}
                            </span>
                            ...&nbsp;
                            <span className={BlogStyles.pickedReadMore}>
                              Read more
                            </span>
                          </div>
                        </div>
                        <div className={BlogStyles.pickedNameStyle}>
                          {filteredPost.node.author.node.name} |{' '}
                          {filteredPost.node.postFieldGroup.minuteRead} Minute Read
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
        {data.wordPress.pages.edges[0].node.blogFieldGroup.ceoPick && (
          <div>
            <h4 className={BlogStyles.ceoContainerTitle}>CEO Picks</h4>
            <div className={BlogStyles.ceoFlexContainer}>
              {data.wordPress.pages.edges[0].node.blogFieldGroup.ceoPick
                .slice(0, 2)
                .map((ceoPicks, index) => {
                  const ceoPicked = ceoPicks.ceoPostPicks
                  const ceoReadTime = ceoPicked.postFieldGroup
                  return (
                    <Link
                      key={index.toString()}
                      to={`/post/${ceoPicked.slug}`}
                    >
                      <div className={BlogStyles.ceoFlexWrapper}>
                        <div className={BlogStyles.ceoInnerFlex}>
                          <div
                            className={BlogStyles.ceoPickedImg}
                            style={{
                              backgroundImage: `url('${ceoPicked.featuredImage.node.sourceUrl}')`
                            }}
                          />
                          <div>
                            <h4 className={BlogStyles.pickedTitleStyle}>
                              {ceoPicked.title}
                            </h4>
                            <div className={BlogStyles.pickedDateStyle}>
                              {formatDateWithDayJs(ceoPicked.date)}
                            </div>
                            <div>
                              <div
                                className={BlogStyles.pickedDescriptionStyle}
                              >
                                <span className={BlogStyles.postExcerpt}>
                                  {parse(ceoPicked.excerpt.substring(0, 70))}
                                </span>
                                ...&nbsp;
                                <span className={BlogStyles.pickedReadMore}>
                                  Read more
                                </span>
                              </div>
                            </div>
                            <div className={BlogStyles.pickedNameStyle}>
                              {ceoPicked.author.node.name} |{' '}
                              {ceoReadTime.minuteRead} Minute Read
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='Deimos blog to share writings from Deimos Martians. Read content
        on Kubernetes, DevOps, Google Cloud, Cloud Computing, and why
        we are the best at what we do.'
        title='Blog'
      />

      <div
        className={composeClasses(
          sharedStyles.wrapper,
          BlogStyles.blogContainer
        )}
      >
        <div>
          <div
            className={composeClasses(
              TeamStyles.verticalContainerInner,
              BlogStyles.verticalBlogInner
            )}
          >
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=' > ' />
            <h2>
              {data.wordPress.pages.edges[0].node.headerSection.heroHeading}
            </h2>
          </div>
        </div>
        <div>
          <div
            className={composeClasses(
              sharedStyles.verticalContainerInner,
              BlogStyles.verticalInner
            )}
          >
            <div className={BlogStyles.featuredPostWrapper}>
              <h3 className={BlogStyles.postContainer}>Featured Posts</h3>
              {getFeaturedPost(featuredPost)}
            </div>
          </div>
        </div>
        <div className={BlogStyles.categoryCaption}>
          <h2>All Blog Categories</h2>
          <p>View all posts in:</p>
        </div>
        <div className={BlogStyles.blogBox}>
          {CategoryList({ categoryData })}
        </div>
      </div>
    </>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default BlogPage
